<template>
  <div id="loading-bg">
    <div class="loading-logo">
      <img style="max-width: 120px;" src="@/assets/images/logo/logo-vertical.png" alt="Logo">
    </div>
    <div class="loading">
      <div class="effect-1 effects"></div>
      <div class="effect-2 effects"></div>
      <div class="effect-3 effects"></div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {},
  async created() {
    const urlStr = (window.location.href || '').replace(/#/, '?');
    var url = new URL(urlStr);
    var state = url.searchParams.get('state');
    await this.$auth0.handleAuthentication(state)
    .then(() => {
      this.$router.push('/login').catch(() => {});
    })
    .catch(e => {
      // console.log(e)
      if (e.error === 'unauthorized') return this.$router.push('/unauthorised').catch(() => {});
      if (e.error === 'invalid_token') return this.$router.push('/login').catch(() => {});
      return this.$router.push('/login').catch(() => {});
    })
  }
}
</script>
